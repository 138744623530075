// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-associations{background:#ddd;border-top-left-radius:5px;border-top-right-radius:5px}div.result-container .tab-content{border-left:1px solid #ddd;border-right:1px solid #ddd;border-bottom:1px solid #ddd;padding:10px}.ReDUDzE6NJ3z4VGAP4ga{padding-bottom:5px;font-size:12px}.ReDUDzE6NJ3z4VGAP4ga:disabled{background-color:#eee}.ReDUDzE6NJ3z4VGAP4ga:disabled:hover{background-color:#eee}`, "",{"version":3,"sources":["webpack://./../@depmap/compute/src/styles/CustomAnalysisResult.scss"],"names":[],"mappings":"AAGE,oBACE,eAAA,CACA,0BAAA,CACA,2BAAA,CAGF,kCACE,0BAAA,CACA,2BAAA,CACA,4BAAA,CACA,YAAA,CAIJ,sBACE,kBAAA,CACA,cAAA,CAEA,+BACE,qBAAA,CAEA,qCACE,qBAAA","sourcesContent":["// TODO: Get rid of :global and convert the corresponding component to CSS\n// Module format.\n:global {\n  .close-associations {\n    background: #ddd;\n    border-top-left-radius: 5px;\n    border-top-right-radius: 5px;\n  }\n\n  div.result-container .tab-content {\n    border-left: 1px solid #ddd;\n    border-right: 1px solid #ddd;\n    border-bottom: 1px solid #ddd;\n    padding: 10px;\n  }\n}\n\n.SaveContextButton {\n  padding-bottom: 5px;\n  font-size: 12px;\n\n  &:disabled {\n    background-color: #eee;\n\n    &:hover {\n      background-color: #eee;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SaveContextButton": `ReDUDzE6NJ3z4VGAP4ga`
};
export default ___CSS_LOADER_EXPORT___;
