// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qBM6IxewTMVP4avME4r3{float:left}input[type=checkbox]{cursor:pointer}.ERAhdlyFLzdYhlytirdV{color:#fff !important;background-color:#a8529d;font-weight:700;font-size:16px;font-family:roboto;text-decoration:none;margin:20px 0px 20px 0}.ERAhdlyFLzdYhlytirdV:hover{background-color:#933186}.ERAhdlyFLzdYhlytirdV:active{background-color:#711971}`, "",{"version":3,"sources":["webpack://./src/common/styles/terms_and_conditions_modal.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEF,qBACE,cAAA,CAIF,sBACE,qBAAA,CACA,wBAAA,CACA,eAAA,CACA,cAAA,CACA,kBAAA,CACA,oBAAA,CACA,sBAAA,CAEF,4BACE,wBAAA,CAEF,6BACE,wBAAA","sourcesContent":[".checkboxText {\n  float: left;\n}\ninput[type=\"checkbox\"] {\n  cursor: pointer;\n}\n\n// same styling as homepage.scss btn-non-public\n.btnNonPublic {\n  color: white !important;\n  background-color: #a8529d;\n  font-weight: 700;\n  font-size: 16px;\n  font-family: roboto;\n  text-decoration: none;\n  margin: 20px 0px 20px 0;\n}\n.btnNonPublic:hover {\n  background-color: #933186;\n}\n.btnNonPublic:active {\n  background-color: #711971;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxText": `qBM6IxewTMVP4avME4r3`,
	"btnNonPublic": `ERAhdlyFLzdYhlytirdV`
};
export default ___CSS_LOADER_EXPORT___;
